import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../containers/SaasModern/Documentation';
import insert_mini_calendar_before from '../../../../../common/src/assets/image/2019/insert_mini_calendar_before.png';
import insert_mini_calendar_after from '../../../../../common/src/assets/image/2019/insert_mini_calendar_after.png';
import mini_calendar_example_template from '../../../../../common/src/assets/image/mini_calendar_example_template.png';
import insert_mini_calendar_formula from '../../../../../common/src/assets/image/insert_mini_calendar_formula.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal provides the ability to insert a mini calendar, either at
        the current edit location, or as part of a template that gets
        automatically generated.{' '}
      </p>
      <p>
        To insert a mini calendar at the current edit location in Excel, Word,
        or PowerPoint, click the <strong>Mini Calendar</strong> button on the
        PrintableCal tab of the ribbon. Select which month should appear first,
        the dimensions (in number of months as <strong>columns </strong>x{' '}
        <strong>rows</strong>), the font size of the dates in the mini calendar,
        and the mini calendar flow direction. After configuring the mini
        calendar, click the <strong>Insert </strong>button to insert at the
        current edit location.
      </p>
      <p>
        <Image
          alt=""
          src={insert_mini_calendar_before}
          style={{ width: 1310 }}
        />
      </p>
      <p>
        <Image
          alt=""
          src={insert_mini_calendar_after}
          style={{ width: 1310 }}
        />
      </p>
      <p>
        Many of the templates included with PrintableCal will automatically
        include a mini calendar. For example, when generating the Week Column
        template, you'll see a mini month calendar in the upper-right corner.
      </p>
      <p>
        <Image
          alt=""
          src={mini_calendar_example_template}
          style={{ width: 1310 }}
        />
      </p>
      <p>
        Similar to how Legends can be automatically inserted, you can also
        customize templates to include a mini calendar. Name the cell range
        where the mini calendar should appear <strong>MiniCalendar</strong>, and
        set the cell value to{' '}
        <strong>
          MiniCalendar(Columns=<em>X</em>,Rows=<em>Y</em>)
        </strong>
        , where <strong>X </strong>is the desired number of columns and{' '}
        <strong>Y </strong>is the desired number of rows.{' '}
      </p>
      <p>
        For example, to generate a mini calendar that contains a 3x4 month grid
        (12 months total), set the value to{' '}
        <strong>MiniCalendar(Columns=3,Rows=4)</strong>. The mini calendar will
        be aligned within the named cell range based on the specified text
        alignment.
      </p>
      <p>
        <Image
          alt=""
          src={insert_mini_calendar_formula}
          style={{ width: 645 }}
        />
      </p>
      <p>
        The MiniCalendar formula also supports a parameter for specifying the
        starting month offset from the starting date specified for the generated
        template. The parameter name is <strong>StartMonthOffset </strong>and
        should be followed by a number, which can be negative, positive, or 0.
        If this parameter isn't specified in the formula, a value of 0 is
        assumed as the default.
      </p>
      <p>
        For example, to start the mini calendar on the previous month, with 4
        months total, the formula would be{' '}
        <strong>MiniCalendar(Columns=4, Rows=1, StartMonthOffset=-1)</strong>.
      </p>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Insert-Mini-Calendar"
      commentsId="commentsplus_post_1201_489"
      title="Insert a Mini Calendar"
      description="PrintableCal provides a mini calendar creator in Excel, Word, and PowerPoint."
      keywords="mini calendar, calendar creator, insert calendars, Excel calendar, Word calendar, PowerPoint calendar, 2020 calendar, printable calendar"
      content={content}
    />
  );
};

export default Documentation;
